import axios from 'axios';
import {baseURL} from '../../config';

let userClient = axios.create({
  baseURL,
  timeout:20000
})

class UserApi {
  async joinWaitList(data){
    return await userClient.post('/users/join-waitlist', data, {
      headers:{}
    });
  }


  async retrieveProfile(account){
    return await userClient.get(`/users/account/${account}`);
  }

  async getProfileByUsername(username){
    return await userClient.get(`/users/username/${username}`);
  }

  async getUserProfile(data){
    return await userClient.post('/users/profile', {}, {
      headers:{"x-token":data.token}
    });
  }

  async getUserGallery(data){
    return await userClient.post('/users/gallery', {}, {
      headers:{"x-token":data.token}
    });
  }

  async updateProfile({data, token}){
    return await userClient.post('/users/update/', data , {
      headers:{"x-token":token}
    });
  }

  async requestVerification({token}){
    return await userClient.post('/users/early-member-verification/', {} , {
      headers:{"x-token":token}
    });
  }
}

export default UserApi;
//@NFTmarket.africa 2022
