import React from 'react';
import {Helmet} from 'react-helmet-async';
import {host} from '../../config';
import {AuthNavBar} from '../../component/nav';
import socialbanner from '../../assets/images/social/nftmarket-africa-social-banner.png';
import {Amplitude} from '../../features/tools';
let EventTracker = new Amplitude();

export default function LandingWrapper(props) {
  let {metadata, profile} = props;
  return (
    <>
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="title" content={metadata.meta_title}/>
      <meta name="description" content={metadata.description}/>
      <link rel="canonical" href={host + window.location.pathname + window.location.search}/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={host + window.location.pathname + window.location.search}/>
      <meta property="og:title" content={metadata.title || "NFT Market Africa"}/>
      <meta property="og:description" content={metadata.description || "The easiest way to Buy & Sell NFTs"}/>
      <meta property="og:image" content={metadata.social_image || host + socialbanner}/>
      <meta property="og:site_name" content="NFT Market Africa"/>
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content={host + window.location.pathname + window.location.search}/>
      <meta property="twitter:title" content={metadata.title || "NFT Market Africa"}/>
      <meta property="twitter:description" content={metadata.description}/>
      <meta property="twitter:image" content={metadata.social_image || host + socialbanner}/>
      <meta name="twitter:site" content="@nftmarketafrica"/>
      <meta name="twitter:image:alt" content={metadata.image_alt || "NFT Market Africa"}/>
    </Helmet>
      <AuthNavBar
      profile={profile}
      auth={props.auth}
      />
    <div className="min-vh-75">
      {props.children }
    </div>

    {
      props.showFaq?
      <>
      <div id="how-it-works">
        <div className="container">
          <div className="row" id="sell">
            <div className="offset-md-2 col-md-8 col-12">
              <div className="mb-4 mt-6">
                <h2 className="mb-0 fw-semi-bold">Frequently Asked Questions.</h2>
              </div>
              <div className="accordion accordion-flush" id="sellAccordion" onClick={()=> EventTracker.logEvent('READ_MERCHANT_APP_FAQ')}>
                <div className="border p-3 rounded-3 mb-2" id="HowTosellMyNft">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <span className="me-auto">What is NFT Merchant App?</span>
                      <span className="collapse-toggle ms-4">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="HowTosellMyNft"
                    data-bs-parent="#sellAccordion"
                    style={{}}
                  >
                    <div className="pt-2">
                       The NFT Merchant App enables you to accept sales and get paid into your Marketplace Wallet.
                    </div>
                  </div>
                </div>
                {/* Card  */}
                {/* Card header  */}
                <div className="border p-3 rounded-3 mb-2" id="HowDoIGetPaid">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span className="me-auto">
                        How do I complete a Sale?
                      </span>
                      <span className="collapse-toggle ms-4">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="HowDoIGetPaid"
                    data-bs-parent="#sellAccordion"
                  >
                    <div className="pt-3">
                       <ul><li>Login with your Gallery Account to see your sales</li><li>Open a sale and click on <strong>Accept Sale</strong> to get paid into your Marketplace Wallet</li></ul>
                    </div>
                  </div>
                </div>
                {/* Card  */}
                {/* Card header  */}
                <div className="border p-3 rounded-3 mb-2" id="HowDoIGetPaid">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span className="me-auto">
                        Do I need Gas?
                      </span>
                      <span className="collapse-toggle ms-4">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="HowDoIGetPaid"
                    data-bs-parent="#sellAccordion"
                  >
                    <div className="pt-3">
                      You may need gas to accept a sale. Contact Support to <a href="https://nftmarket.africa/contact/support-ticket" target="blank_">Borrow Gas</a>
                    </div>
                  </div>
                </div>
                {/* Card  */}
                {/* Card header  */}
                <div className="border p-3 rounded-3 mb-2" id="HowToBecomeACreator">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <span className="me-auto">
                        When do I get paid?
                      </span>
                      <span className="collapse-toggle ms-4">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="HowToBecomeACreator"
                    data-bs-parent="#sellAccordion"
                  >
                    <div className="pt-3">
                      Your Marketplace Wallet will be immediately credited after you accept a sale.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      :
      null
    }
    <div className="footer mt-15">
      <div className="container">
        <div className="row align-items-center g-0 border-top py-2">
          {/* Desc */}
          <div className="col-md-6 col-12 text-center text-md-start">
            <span>© 2022 NFT Merchant App from <a href="https://nftmarket.africa" target="blank_">NFT Market Africa</a>. All Rights Reserved.</span>
          </div>
          {/* Links */}
          <div className="col-12 col-md-6">
            <nav className="nav nav-footer justify-content-center justify-content-md-end">
              <a className="nav-link active ps-0" href="https://nftmarket.africa/privacy-policy">
                Privacy
              </a>
              <a className="nav-link" href="https://nftmarket.africa/terms-and-conditions">
                Terms{" "}
              </a>
              <a className="nav-link" href="https://nftmarket.africa/contact/feedback" target="blank_">
                Feedback
              </a>
              <a className="nav-link" href="https://nftmarket.africa/contact/support-ticket" target="blank_">
                Support
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
//@NFTmarket.africa 2022
