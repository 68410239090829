import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useMoralis, useWeb3Transfer } from "react-moralis";
import {retrieveOrder} from '../../../features/checkout/checkoutSlice';
import {currencyFormat, sliceCharacter} from '../../../features/tools';
import OrderApi from '../../../service/api/order_api';
import Cookie from 'js-cookie';
import {Amplitude} from '../../../features/tools';
let EventTracker = new Amplitude();
let api = new OrderApi();

export default function TransferCardFooter(props){
  let dispatch = useDispatch();
  let {post, order} = props;
  let user = useSelector( state => state.auth.value);
  let [data, setData] = useState({});
  let [txHash, setTxHash] = useState(null);
  let [loading, setLoading] = useState(false);
  let [view, setView] = useState('default');
  let [meta_data, setMetadata] = useState(false);
  let [error_, setError] = useState(true);
  const { Moralis } = useMoralis();

  useEffect(()=>{
    if(post && post.alchemy_data){
      let {alchemy_data} = post;
      let {metadata} = alchemy_data;
      metadata = JSON.parse(metadata)
      setData(alchemy_data)
      setMetadata(metadata)
    }
  },[post])

  const { fetch, error, isFetching } = useWeb3Transfer({
    type: data?.contract_type?.toLowerCase(),
    receiver: order?.collectors_address,
    contractAddress:data?.token_address,
    tokenId:data?.token_id,
    amount:1
  });

  useEffect(()=>{
    if(error){
      EventTracker.logEvent('BLOCKCHAIN_TRANSACTION_ERROR')
      setError('Blockchain Error');
    }
  },[error])

  let handleTransfer =async()=>{
    EventTracker.logEvent('ACCEPT_SALE_AUTO')
    setError(null);
    Moralis.enableWeb3().then(async()=>{
      let token = Cookie.get('c-token');
      let result = await fetch()
      if(result && result.wait){
        setLoading(true);
        result.wait().then((confirmed_transaction)=>{
          EventTracker.logEvent('BLOCKCHAIN_TRANSACTION_SUCCESS')
          let {
            blockHash,
            confirmations,
            from,
            to,
            transactionHash
          } = confirmed_transaction;
          let data = {
            request:{
              blockHash,
              transactionHash,
              from,
              to,
              confirmations,
              order_id:order['order_id'],
            },
            token
          }
          api.acceptSale(data).then((response)=>{
            EventTracker.logEvent('SALE_VERIFICATION_COMPLETE')
            setLoading(false);
            dispatch(retrieveOrder(order.order_id))
          }).catch((err)=>{
            EventTracker.logEvent('SALE_VERIFICATION_FAILURE')
            setLoading(false);
            if(!err.response || !err.response.data){
              setError("Network Error");
            }else{
              setError(err.response.data.error);
            }
          })
        }).catch((err)=>{
          setLoading(false);
          setError('Error confirming blockchain tx');
          EventTracker.logEvent('BLOCKCHAIN_TRANSACTION_SUCCESS')
        })
      }
    })
  }


  let handleManualConfirmation =()=> {
    EventTracker.logEvent('ACCEPT_SALE_MANUALLY')
    setError(null);
    let token = Cookie.get('c-token');
    if(txHash && token){
      setLoading(true);
      let data = {
        request:{
          transactionHash:txHash,
          order_id:order['order_id']
        },
        token
      }
      api.acceptSale(data).then((response)=>{
        EventTracker.logEvent('SALE_VERIFICATION_COMPLETE')
        dispatch(retrieveOrder(order.order_id))
        setLoading(false);
      }).catch((err)=>{
        EventTracker.logEvent('SALE_VERIFICATION_FAILURE')
        if(!err.response || !err.response.data){
          setError("Network Error");
        }else{
          setError(err.response.data.error);
        }
        setLoading(false);
      })
    }
  }

  let handleEnterHash =(e)=> {
    EventTracker.logEvent('ENTERED_TRANSACTION_HASH')
    let {value} = e.target;
    if(value){
      setTxHash(value);
    }else{
      setTxHash(null)
    }
  }

  return (
    <div className="col-lg-6 col-md-6 col-12 py-6">
      <div className="card">
        <div className="card-body">
          <div className="card border">
            <div className="card-header">
            {
              (order.is_complete&&order.status==="complete")?
              <h3 className="mb-0"><i className="fas fa-check-circle text-success"/> Your sale is complete!</h3>
              :
              <h3 className="mb-0"><i className="fas fa-check-circle text-success"/> Accept your sale</h3>
            }
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h4>Item</h4>
                <h4>{meta_data.name?meta_data.name.slice(0,25):''}</h4>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h4>Amount</h4>
                <h4>{currencyFormat(order.item_amount,'USD')}</h4>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h4>From Gallery</h4>
                <h4>{sliceCharacter(order.creators_gallery_address)}</h4>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h4>Delivery Address</h4>
                <h4>{sliceCharacter(order.collectors_address)}</h4>
              </div>
              {
                (order.creators_gallery_address===user.account)?
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="text-success">Gallery Connected <i className='fe fe-check-circle'/></h4>
                </div>
                :
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="text-danger">Invalid Gallery Connected</h4>
                </div>
              }
              <hr/>
              <div className="d-flex justify-content-between align-items-center">
                <h4>{(order.is_complete&&order.status==="complete")?'Credited':'Available Credit'}</h4>
                <h2 className='text-success'>{currencyFormat(order.item_amount,'USD')}</h2>
              </div>
            </div>
            <div className="card-footer border-0">
              <div className="d-grid gap-2">
              <small className='text-danger'>{error_}</small>
              {
                (order.is_complete&&order.status==="complete")?
                <button type="button" className="btn btn-success">
                  Sale is Complete
                </button>
                :
                (!isFetching&&!loading)?
                <>
                {
                  view==="manual"?
                  <>
                  <input className="form-control" placeholder="Enter Transaction Hash" onChange={handleEnterHash} defaultValue={txHash}/>
                  <button className="btn btn-light" type="button" disabled={!txHash} onClick={handleManualConfirmation}>
                   Verify Transfer
                  </button>
                  <p className="clickable" onClick={()=> setView('default')}>Reset</p>
                  </>
                  :
                  <>
                  <button className="btn btn-light" type="button" onClick={handleTransfer}>
                   Accept Sale
                  </button>
                  <p className="clickable" onClick={()=> setView('manual')}>Accept Manually</p>
                  </>
                }
                </>
                :
                <button
                  disabled
                  className="btn btn-success">
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </button>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
//@NFTmarket.africa 2022
