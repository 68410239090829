import axios from 'axios';
import {baseURL} from '../../config';


let galleryClient = axios.create({
  baseURL,
  timeout:20000
})

class GalleryApi {

  async authGallery({signature, hash}){
    return await galleryClient.post('/gallery/auth', {}, {
      headers:{"x-sign":signature, "x-nonce":hash}
    });
  }

  async createGallery(data){
    return await galleryClient.post(`/gallery/create`, data.details, {headers:{'x-token':data.token}});
  }

  async getGalleries(token){
    return await galleryClient.get(`/gallery`, {headers:{'x-token':token}});
  }

  async getGalleryItems(data){
    return await galleryClient.post(`/gallery/items`, {gallery_id:data.gallery_id}, {headers:{'x-token':data.token}});
  }

  async getGallerySales(data){
    return await galleryClient.post(`/gallery/sales`, {}, {headers:{'x-token':data.token}});
  }
}

export default GalleryApi;
//@NFTmarket.africa 2022
