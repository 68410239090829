import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser';
import amplitude from 'amplitude-js';
import store from '../../store';
const gatewayTools = new IPFSGatewayTools();

let eventType = {
  ON_MERCHANT_APP:'ON_MERCHANT_APP',
  READ_MERCHANT_APP_FAQ:'READ_MERCHANT_APP_FAQ',

  CONNECT_GALLERY:'CONNECT_GALLERY',
  GALLERY_AUTH_SUCCESS:'GALLERY_AUTH_SUCCESS',
  GALLERY_AUTH_FAILURE:'GALLERY_AUTH_FAILURE',

  ON_GALLERY_SALES:'ON_GALLERY_SALES',

  ACCEPT_SALE_AUTO:'ACCEPT_SALE_AUTO',
  BLOCKCHAIN_TRANSACTION_ERROR:'BLOCKCHAIN_TRANSACTION_ERROR',
  BLOCKCHAIN_TRANSACTION_SUCCESS:'BLOCKCHAIN_TRANSACTION_SUCCESS',
  ACCEPT_SALE_MANUALLY:'ACCEPT_SALE_MANUALLY',
  ENTERED_TRANSACTION_HASH:'ENTERED_TRANSACTION_HASH',
  SALE_VERIFICATION_COMPLETE:'SALE_VERIFICATION_COMPLETE',
  SALE_VERIFICATION_FAILURE:'SALE_VERIFICATION_FAILURE'
}

export class Amplitude{

  initialize(){
    let {user} = store.getState();
    let {profile} = user.value;
    if(profile){
      return amplitude.getInstance().init("3a6082cd9b005c911c22324ced089c58", (profile.username || profile.account));
    }
    return amplitude.getInstance().init("3a6082cd9b005c911c22324ced089c58");
  }

  logEvent(event_){
    this.initialize();
    let eventName = eventType[event_];
    if(eventName){
      return amplitude.getInstance().logEvent(eventType[event_]);
    }
    console.error('Invalid event', event_);
  }

}


export const getURL =(url)=> {
  if(url){
    let detectIpfs = gatewayTools.containsCID(url)
    if(!detectIpfs.containsCid) return url
    let url_ = new URL(url)
    return `https://nftmarketafrica.mypinata.cloud${url_.pathname}`
  }else{
    return "no url"
  }
}


export const currencyFormat =(amount, currency)=>{
  return (parseFloat(amount)).toLocaleString('en-US', {
    style: 'currency',
    currency,
  })
}


export const getPrice =(utility_prices, utilities, item_price, options)=> {
  if(utilities && utility_prices){
    item_price = parseFloat(item_price)
    let included = Object.values(utilities).filter((utility) => utility.include);
    included.forEach((item, i) => {
      // console.log(utility_prices[item.slug]);
      item_price = utility_prices[item.slug] + item_price
    });
    if(options &&options.noFormat) return item_price;
    return currencyFormat(item_price, 'USD');
  }else{
    return currencyFormat(0, 'USD');
  }
}


export const getPricePlusFee = (price, fee)=> {
  fee = parseFloat(fee);
  price = parseFloat(price);
  let perentage = (fee / 100) * price;
  let total = price + perentage;
  return currencyFormat(total, 'USD');
}


export const sliceCharacter=(char)=>{
  return `${char.slice(0,6)}......${char.slice(char.length-6, char.length)}`
}

//@nftmarket.africa 2022
