import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, Link} from 'react-router-dom';
import {retrieveOrder} from '../features/checkout/checkoutSlice';
import {connectAccount} from '../features/auth/authSlice';
import userApi from '../service/api/user_api';
import { MoralisProvider } from "react-moralis";
// import LoadingCard from '../component/ui_kits/loading_card';
import {
  LandingWrapper
} from './wrapper';
import {
  Post
} from '../component';
import TransferCard from '../component/ui_kits/web3/transfer_card';

const api = new userApi();

const serverUrl = "https://kihn9rzsvhbt.usemoralis.com:2053/server";
const appId = "G4SClSFcqfRO83Khrm9ZFpNG84Rvv5HmGYDHBmZr";

let metadata = {
  title:"NFT Market Africa - Accept Sale",
  meta_title:"NFT Market Africa - Accept Sale",
  description:"Accept a sale a get paid"
}

const Sale =(props)=> {
  let [profile, setProfile] = useState({})
  let dispatch = useDispatch()
  let {order_id} = useParams()
  let [meta_data, setMetadata] = useState(false)
  let user = useSelector( state => state.user.value)
  let {loggedIn, loggingIn} = user;
  let {retrieving, retrieved_order} = useSelector(state => state.checkout.value)
  let {post, order} = retrieved_order;

  useEffect(()=>{
    dispatch(retrieveOrder(order_id))
  },[dispatch, order_id])

  useEffect(()=>{
    if(post && post.alchemy_data){
      let {alchemy_data} = post;
      let {metadata} = alchemy_data;
      metadata = JSON.parse(metadata)
      setMetadata(metadata)
    }
  },[post])

  useEffect(()=>{
    if(order&&order.creator){
      api.retrieveProfile(order.creator).then((response)=>{
        setProfile(response.data);
      }).catch((err)=>{
        console.error(err);
      })
    }
    return function cleanUp(){
      setProfile({})
    }
  },[order])

  return (
    <MoralisProvider serverUrl={serverUrl} appId={appId}>
      <LandingWrapper
      showNavigation={true}
      profile={profile}
      metadata={metadata}
      meta_data={meta_data}
      auth={{loggedIn, loggingIn}}
      >
        <section className="container py-6">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-lg-10">
              <span className="fs-3 fw-bold">
                <Link to="/sales">
                  <i className="fe fe-arrow-left fw-bolder px-3"/>
                </Link>
              </span>
            </div>
          {
            retrieving?
            <div className="col-12 col-md-7 col-lg-5 py-6 text-center">
              <div className="spinner-border spinner-border-lg" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
            :
            !retrieving&&post&&order&&profile?
            <div className="col-12 col-md-7 col-lg-5 py-6">
              <Post
                profile={profile}
                data={post}
                order={order}
                auth={user}
                login={connectAccount}
                resolution={{height:400}}
              />
            </div>
              :
              <div className="col-12 col-md-7 col-lg-5 py-6">
                <div className="card py-6 text-center text-danger">
                </div>
              </div>
            }
            {
              order&&post?
              <TransferCard order={order} post={post} />
              :
              null
            }
          </div>
        </section>
      </LandingWrapper>
    </MoralisProvider>
  )
}
export default Sale;
//@ NFTmarket.africa 2022
