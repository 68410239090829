import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import WalletApi from '../../service/api/wallet_api';
import Cookies from 'js-cookie';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
let api = new WalletApi();
Notify.init({
  position:'left-bottom'
})

export const getWallets = createAsyncThunk(
  'wallet/GET_WALLETS',
  async(arg, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let data = {
          token
        }
        let response = await api.getWallets(data);
        return response.data;
      }else{
        throw thunkAPI.rejectWithValue('Token is required');
      }
    }catch(err){
      if(err.response && err.response.data){
        throw thunkAPI.rejectWithValue(err.response.data, {});
      }else{
        err.error = "Network Error";
        throw thunkAPI.rejectWithValue(err);
      }
    }
  }
)

export const activateWallet = createAsyncThunk(
  'wallet/ACTIVATE',
  async(arg, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let data = {
          token
        }
        api.activateWallet(data).then(()=>{
          thunkAPI.dispatch(getWallets())
          return true
        })
      }else{
        throw thunkAPI.rejectWithValue('Token is required');
      }
    }catch(err){
      if(err.response && err.response.data){
        throw thunkAPI.rejectWithValue(err.response.data, {});
      }else{
        err.error = "Network Error";
        throw thunkAPI.rejectWithValue(err);
      }
    }
  }
)

export const walletSlice = createSlice({
  name:"wallet",
  initialState:{
    value:{
      data:[],
      transactions:[],
      activating:false,
      loading_wallets:false,
      loading_transactions:false,
      error:null
    }
  },
  reducers:{
  },
  extraReducers:(builder)=>{
    builder.addCase(getWallets.pending, (state, action)=> {
      state.value = {
        ...state.value,
        loading_wallets:true,
        error:null
      }
    })
    builder.addCase(getWallets.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        data:action.payload.data,
        loading_wallets:false,
        error:null
      }
    })
    builder.addCase(getWallets.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        activating:true,
        error:null
      }
    })
    builder.addCase(activateWallet.pending, (state, action)=> {
      state.value = {
        ...state.value,
        activating:true,
        error:null
      }
    })
    builder.addCase(activateWallet.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        activating:false,
        error:null
      }
    })
    builder.addCase(activateWallet.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        activating:false,
        error:action.payload
      }
    })
  }
})
export const {enter_action_here} = walletSlice.actions;
export default walletSlice.reducer;

//@NFTmarket.africa 2022
