import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser';
import blank from '../assets/images/tools/blank_image.png';
import {createPost} from '../features/post/postSlice';
const gatewayTools = new IPFSGatewayTools();


let GalleryCard =(props)=> {
  let dispatch = useDispatch()
  let [properties, setProperties] = useState({url:blank})
  let {data} = props;

  useEffect(()=>{
    if(data&&data.media&&data.media[0]&&data.media[0]['uri']){
      setProperties( prev => {
        return {
          ...prev,
          url:getURL(data.media[0]['uri']['gateway'])
        }
      })
    }else if((data&&data.media&&data.media[0]&&data.media[0]['gateway']) || data.metadata['image']){
      setProperties( prev => {
        return {
          ...prev,
          url:getURL(data.media[0]['gateway'] || data.metadata['image'])
        }
      })
    }else{
      console.log('media not detected');
    }
  },[data])

  let getURL =(url)=> {
    if(url){
      let detectIpfs = gatewayTools.containsCID(url)
      if(!detectIpfs.containsCid) return url
      let url_ = new URL(url)
      return `https://nftmarketafrica.mypinata.cloud${url_.pathname}`
    }else{
      return "no url"
    }
  }

  // let mediaTypeIsVideo =(url)=> {
  //   if(url){
  //     let url_ = new URL(url)
  //     let {pathname} = url_
  //     //get url extension
  //     let type = pathname.split('.').pop()
  //     if(type.match('mp4')){
  //       return true
  //     }else{
  //       return false
  //     }
  //   }else{
  //     return false
  //   }
  // }

  let handleCreatePost=()=>{
    let {contract, id} = data
    let details = {
      contract_address:contract['address'],
      token_id:id['tokenId']
    }
    dispatch(createPost(details))
  }

  return (
    <div className="col-lg-4 col-6">
      {/* Card */}
      <div className="card mb-4 card-hover">
        <div className="card-img-top pt-3" style={{height:"300px"}}>
          <img
            src={properties.url}
            alt=""
            className="card-img-top rounded-top-md"
            style={{height: '100%', width: '100%', objectFit: 'contain'}}
          />
        </div>
        {/* Card body */}
        <div className="card-body">
          <h4 className="mb-2 text-truncate-line-2 ">
            <a href="course-single.html" className="text-inherit">
              {data.title}
            </a>
          </h4>
        </div>
        {/* Card footer */}
        <div className="card-footer">
          {/* Row */}
          <div className="row align-items-center g-0">
            <div className="col-auto">
              <i className="fab fa-ethereum"></i>
            </div>
            <div className="col ms-2">
            </div>
            <div className="col-auto">
              <button
                className="btn btn-sm btn-light btn-icon p-0 border-0 rounded-circle"
                type="button"
                data-bs-toggle="modal"
                data-bs-target={"#postModal"+data.id.tokenId}
                aria-controls={"postModal"+data.id.tokenId}
              >
                <i className="fas fa-plus fs-3"></i>
              </button>

              <div
                className="modal fade"
                id={"postModal"+data.id.tokenId}
                tabIndex={-1}
                role="dialog"
                aria-labelledby="postModalTitle"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id={"postModalTitle"+data.id.tokenId}>
                        {data.title}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="card py-3" style={{height:"400px"}}>
                        <img
                          src={properties.url}
                          alt=""
                          className="rounded-3"
                          style={{height: '100%', width: '100%', objectFit: 'contain'}}
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="button" className="btn btn-primary" onClick={handleCreatePost}>
                        Post
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GalleryCard;
//@NFTmarket.africa 2022
