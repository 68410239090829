import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
Notify.init({position:'left-bottom'})

export const createNotification = createAsyncThunk(
  'utilities/CREATE_NOTIFICATION',
  async(action, thunkAPI)=>{
    console.log(action, 'action here');
    switch (action.type) {
      case 'failure':
        Notify.failure(action.message);
        break;
      default:
        Notify.success(action.message);
    }
  }
)

export const utilitySlice = createSlice({
  name:'utilities',
  initialState:{
    value:{
      action:''
    }
  },

  reducers:{

  },
  extraReducers:(builder)=> {
    builder.addCase(createNotification.fulfilled, (state, action)=> {
      state.value = {
        action:action.payload
      }
    })
  }
})

export const {enter_action} = utilitySlice.actions;
export default utilitySlice.reducer;
//@NFTmarket.africa
