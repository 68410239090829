import React from 'react';
import '@splidejs/splide/dist/css/splide.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {disconnectAccount, getToken} from '../features/auth/authSlice';
import {
  LandingWrapper
} from './wrapper';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();


const GalleryLogin =(props)=> {
  let dispatch = useDispatch();
  let {loading, error} = useSelector((state)=> state.auth.value)
  let metadata = {
    title:"NFT Market Africa | Gallery",
    meta_title:"Complete a sale to get paid",
    description:"Complete a sale to get paid"
  }

  let handleGetToken =()=> {
    EventTracker.logEvent('CONNECT_GALLERY')
    dispatch(getToken()).then((response)=>{
      if(!response.error){
        EventTracker.logEvent('GALLERY_AUTH_SUCCESS')
      }else{
        EventTracker.logEvent('GALLERY_AUTH_FAILURE')
      }
    }).catch((err)=>{
      EventTracker.logEvent('GALLERY_AUTH_FAILURE')
    })
  }
  return (
    <LandingWrapper
    showNavigation={true}
    metadata={metadata}
    profile={{}}
    auth={{}}
    showFaq={true}
    >
      <div className="container d-flex flex-column pt-15">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-5 col-md-8 py-xl-0">
            {/* Card */}
            <div className="card shadow-sm rounded-3">
              {/* Card body */}
              <div className="card-body p-6">
                <div className="mb-4">
                  <a href="/">
                    <img
                      src="../assets/images/brand/logo/logo-icon.svg"
                      className="mb-4"
                      alt=""
                    />
                  </a>
                  <h1 className="mb-1 fw-bold">Sign In</h1>
                  <p>Signin with your gallery account</p>
                </div>
                <div>
                  <div className="d-grid">
                  {
                    loading?
                    <>
                    <button className="btn btn-primary" type="button" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      {" "}Please wait...
                    </button>
                    <button type="button" onClick={()=> dispatch(disconnectAccount())} className="btn btn-primary btn-lg my-3">
                      Cancel
                    </button>
                    </>
                    :
                    <button type="button" onClick={handleGetToken} className="btn btn-primary btn-lg ">
                      Connect
                    </button>
                  }
                  </div>
                  <small className="text-danger">{error}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default GalleryLogin;
//@ NFTmarket.africa 2022
