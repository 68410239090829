import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, getRedirectResult, signInWithRedirect, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyBugSgqUtId371mYkgvosvYVEsU3KmuqGA",
  authDomain: "nft-market-sales-app.firebaseapp.com",
  projectId: "nft-market-sales-app",
  storageBucket: "nft-market-sales-app.appspot.com",
  messagingSenderId: "745756431235",
  appId: "1:745756431235:web:008e1e12300660b5b7dcb6",
  measurementId: "G-VDNBCF27C9"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth(app);
const baseURL = 'https://nftmarket-africa.uc.r.appspot.com/';
// const baseURL = 'https://nft-market-africa.herokuapp.com';
const host = 'https://nftmarket.africa';
// const baseURL = 'http://localhost:3001/';
export{
  firebase,
  app,
  auth,
  analytics,
  storage,
  ref,
  TwitterAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  signInWithPopup,
  uploadBytesResumable,
  getDownloadURL,
  baseURL,
  host
}
