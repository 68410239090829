import React from 'react';
import logo2 from '../../assets/images/logo/merchant.png';
import {host} from '../../config';


let AuthNavBar = (props)=> {
  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-default shadow-none" style={{zIndex:1}}>
      <div className="container px-0">
        <a className="navbar-brand" href="/">
          <img src={logo2} width={150} alt="logo"/>
        </a>
        {/* Button */}
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-default"
          aria-controls="navbar-default"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar top-bar mt-0" />
          <span className="icon-bar middle-bar" />
          <span className="icon-bar bottom-bar" />
        </button>
        {/* Collapse */}
        <div className="collapse navbar-collapse" id="navbar-default">
          <ul className="navbar-nav offset-lg-4">
            <li className="nav-item">
              <a
                className="nav-link fs-4"
                href={`${host}/storefront`}
                target="blank_"
              >
                Marketplace
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link fs-4"
                href="#how-it-works"
              >
                How it works
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </>
  )
}
export default AuthNavBar;
//@nftmarket.africa 2022
