import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import galleryApi from '../../service/api/gallery_api';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
Notify.init({position:'left-bottom'})
let api = new galleryApi();



export const createGallery = createAsyncThunk(
  'gallery/CREATE_GALLERY',
  async(details, thunkAPI)=>{
    let token = Cookies.get('c-token')
    if(token){
      let request = {
        details,
        token
      }
      try{
        let response = await api.createGallery(request)
        Notify.success(response.data.message);
        return response
      }catch(err){
        if(err.response && err.response.data){
          Notify.failure(err.response.data.error);
          throw thunkAPI.rejectWithValue(err.response.data.error);
        }else{
          Notify.failure('Network error');
          err.error = "Network Error";
          throw thunkAPI.rejectWithValue(err);
        }
      }
    }else{
      throw thunkAPI.rejectWithValue('Token is required');
    }
  }
)



export const getGalleries = createAsyncThunk(
  'gallery/GET_GALLERIES',
  async(arg, thunkAPI)=>{
    let token = Cookies.get('c-token')
    if(token){
      try{
        let response = await api.getGalleries(token);
        return response.data;
      }catch(err){
        if(err.response && err.response.data){
          Notify.failure(err.response.data.error);
          throw thunkAPI.rejectWithValue(err.response.data.error);
        }else{
          Notify.failure('Network error');
          err.error = "Network Error";
          throw thunkAPI.rejectWithValue(err);
        }
      }
    }else{
      throw thunkAPI.rejectWithValue('Token is required');
    }
  }
)



export const getGalleryItems = createAsyncThunk(
  'gallery/GET_ITEMS',
  async(gallery_id, thunkAPI)=>{
    let token = Cookies.get('c-token')
    if(token){
      try{
        let request = {gallery_id, token};
        let response = await api.getGalleryItems(request);
        return response.data;
      }catch(err){
        if(err.response && err.response.data){
          Notify.failure(err.response.data.error);
          throw thunkAPI.rejectWithValue(err.response.data.error);
        }else{
          Notify.failure('Network error');
          err.error = "Network Error";
          throw thunkAPI.rejectWithValue(err);
        }
      }
    }else{
      throw thunkAPI.rejectWithValue('Token is required');
    }
  }
)


export const gallerySlice = createSlice({
  name:'gallery',
  initialState:{
    value:{
      data:[],
      items:{},
      loading:false,
      creating:false,
      error:null
    }
  },

  reducers:{

  },
  extraReducers:(builder)=> {
    builder.addCase(createGallery.pending, (state, action)=> {
      state.value = {
        ...state.value,
        creating:true,
        error:null
      }
    })
    builder.addCase(createGallery.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        creating:false,
        error:null
      }
    })
    builder.addCase(createGallery.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        creating:false,
        error:action.payload
      }
    })
    builder.addCase(getGalleries.pending, (state, action)=> {
      state.value = {
        ...state.value,
        loading:true,
        error:null
      }
    })
    builder.addCase(getGalleries.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        data:action.payload,
        loading:false,
        error:null
      }
    })
    builder.addCase(getGalleries.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        loading:false,
        error:action.payload
      }
    })
    builder.addCase(getGalleryItems.pending, (state, action)=> {
      state.value = {
        ...state.value,
        loading:true,
        error:action.payload
      }
    })
    builder.addCase(getGalleryItems.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        items:action.payload,
        loading:false,
        error:null
      }
    })
    builder.addCase(getGalleryItems.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        loading:false,
        error:action.payload
      }
    })
  }
})

export const {enter_action_here} = gallerySlice.actions;
export default gallerySlice.reducer;
//@NFTmarket.africa
