import React from "react";
import {Navigate } from "react-router-dom";
import {useSelector} from 'react-redux';

function AuthRoute({children}){
  let {loggedIn} = useSelector( state => state.auth.value)
  return loggedIn? <Navigate to="/sales"/> : children;
}

export default AuthRoute;
//@NFTmarket.africa 2022
