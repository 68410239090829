import React, {useEffect, useState} from 'react';
import '@splidejs/splide/dist/css/splide.min.css';
import AnimationCard from '../component/ui_kits/animation/animation_card';
import complete from '../assets/animations/complete.json';
import {
  LandingWrapper
} from './wrapper';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();

const Home =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_MERCHANT_APP')
    }
  },[pageLoaded, setPageLoaded])

  let metadata = {
    title:"NFT Merchant App",
    meta_title:"Sell NFTs online and in-store",
    description:"Sell NFTs online and in-store"
  }
  return (
    <LandingWrapper
    showNavigation={true}
    metadata={metadata}
    profile={{}}
    auth={{}}
    showFaq={true}
    >
      {/* container */}
      <div className="container py-10 py-lg-15 mt-6 rounded-3">
        {/* row */}
        <div className="row justify-content-between align-items-center shadow-sm rounded rounded-3 bg-white p-6">
          <div className="col-lg-6 col-12 py-6" style={{zIndex:2}}>
            <div>
              <div className=" text-center text-md-start ">
                {/* heading */}
                <h1 className="display-1 fw-bold  mb-3">
                  NFT Point of Sale
                </h1>
                {/* lead */}
                <p className="lead">
                Sell NFTs online and in-store
                </p>
                <a href="/sign-in" className="btn btn-lg btn-primary">
                  Start
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-12 d-flex justify-content-center">
            <AnimationCard animation={complete} style={{width:'50%'}}/>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Home;
//@ NFTmarket.africa 2022
