import { useLottie } from "lottie-react";
import animation from "../../../assets/animations/delivery_guy.json";

const interactivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.2],
      type: "stop",
      frames: [0],
    },
    {
      visibility: [0.2, 0.45],
      type: "seek",
      frames: [0, 45],
    },
    {
      visibility: [0.45, 1.0],
      type: "loop",
      frames: [45, 60],
    },
  ],
};

const Animation = (props) => {
  const options = {
    animationData: props.animation || animation,
    interactivity,
    loop: (props.options && !props.options.loop) ? false : true,
    autoplay: true,
    style:(props.style) ? {...props.style} : {},
  };

  const { View } = useLottie(options);

  return View
};

export default function Animate(props){return ( <><Animation {...props}/><div className="card-img-overlay"></div></>)};
//@nftmarket.africa 2022
