import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '@splidejs/splide/dist/css/splide.min.css';
import {useDispatch, useSelector} from 'react-redux';
import {disconnectAccount} from '../features/auth/authSlice';
import {sliceCharacter, currencyFormat} from '../features/tools';
import GalleryApi from '../service/api/gallery_api';
import Cookies from 'js-cookie';
import {
  LandingWrapper
} from './wrapper';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();
let api = new GalleryApi();



const Sales =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_GALLERY_SALES')
    }
  },[pageLoaded, setPageLoaded])

  let dispatch = useDispatch();
  let navigate = useNavigate();
  let [sales, setSales] = useState([])
  let [loading, setLoading] = useState(false)
  let {account} = useSelector((state)=> state.auth.value);

  useEffect(()=> {
    let token = Cookies.get('c-token');
    if(token){
      setLoading(true)
      api.getGallerySales({token}).then((response)=>{
        setSales(response.data);
        setLoading(false)
      }).catch((err)=> {
        setLoading(false)
        setSales([])
        dispatch(disconnectAccount())
      })
    }
  },[dispatch])

  let metadata = {
    title:"NFT Market Africa | Gallery",
    meta_title:"Accept a sale and get paid",
    description:"Accept a sale and get paid"
  }
  return (
    <LandingWrapper
    showNavigation={true}
    metadata={metadata}
    profile={{}}
    auth={{}}
    >
      <div className="py-10 bg-white">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-10 py-3">
              <div className="card bg-light shadow-none rounded-4">
                <div className="card-body">
                  <div className="py-3">
                    <span className="fs-5 fw-bold">Connected to</span>
                    <h3 className="display-4 py-2">{sliceCharacter(account)}</h3>
                    <div className="d-flex justify-content-between">
                      <button className="btn btn-primary brand-button btn-lg" disabled>
                        Sell now <i className=" fe fe-shopping-cart"/>
                      </button>
                      {
                        loading?
                        <button className="btn btn-light brand-button btn-lg me-2" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {" "}Please wait...
                        </button>
                        :
                        <button className="btn btn-light brand-button btn-lg" onClick={()=> dispatch(disconnectAccount())}>
                          <i className=" fe fe-power"/> Signout
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-flex flex-column py-6">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8 col-md-8 py-xl-0">
            {/* Card */}
            <div className="card mb-4">
              {/* Card header */}
              <div className="card-header border-bottom-0">
                <h3 className="mb-0">{sales.length} sales</h3>
                <p className="mb-0">Click to view sale.</p>
              </div>
              {/* Table */}
              <div className="table-invoice table-responsive border-0">
                <table className="table table-hover mb-0 text-nowrap">
                  <thead className="table-light">
                    <tr>
                      <th scope="col" className="border-bottom-0">
                        SALE ID
                      </th>
                      <th scope="col" className="border-bottom-0">
                        STATUS
                      </th>
                      <th scope="col" className="border-bottom-0">
                        AMOUNT
                      </th>
                      <th scope="col" className="border-bottom-0">
                        TOKEN ID
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    sales && sales.length?
                    sales.map( (order, i) =>
                      <tr key={i} className="clickable" onClick={()=> navigate(`/sale/${order.order_id}`)}>
                        <td>
                          <a href={`/sale/${order.order_id}`}>{order.order_id}</a>
                        </td>
                        <td>
                        {
                          order.status === 'unpaid'?
                          <span className="badge bg-danger">{order.status}</span>
                          :
                          order.status === 'pending'?
                          <span className="badge bg-warning">{order.status}</span>
                          :
                          order.status === 'processing'?
                          <span className="badge bg-success-light">{order.status}</span>
                          :
                          order.status === 'complete'?
                          <span className="badge bg-success">{order.status}</span>
                          :
                          order.status === 'hold'?
                          <span className="badge bg-warning">{order.status}</span>
                          :
                          <span className="badge bg-light">{order.status}</span>
                        }
                        </td>
                        <td>{currencyFormat(order.item_amount, 'USD')}</td>
                        <td>{sliceCharacter(order.token_id)}</td>
                      </tr>
                    )
                    :
                    null
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Sales;
//@ NFTmarket.africa 2022
