import React from "react";
import {Navigate } from "react-router-dom";
import {useSelector} from 'react-redux';

function Protected({children}){
  let {loggedIn} = useSelector( state => state.auth.value)
  return loggedIn? children : <Navigate to="/sign-in"/>;
}

export default Protected;

//@NFTmarket.africa 2022
