import axios from 'axios';
import {baseURL} from '../../config';

let orderClient = axios.create({
  baseURL,
  timeout:50000
})

class OrderApi {

  async retrieveOrder(invoice_id){
    return await orderClient.get(`/orders/${invoice_id}`);
  }

  async createOrder(data){
    return await orderClient.post('/orders/initiate', data.order, {
      headers:{"x-token":data.token}
    });
  }

  async getOrders(data){
    return await orderClient.post('/orders', data.request, {
      headers:{"x-token":data.token}
    });
  }

  async acceptSale(data){
    return await orderClient.post('/orders/accept', data.request, {
      headers:{"x-token":data.token}
    });
  }
}

export default OrderApi;
//@NFTmarket.africa 2022
