import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
  // Navigate
} from "react-router-dom";
import './assets/css/theme.css';
import './assets/css/custom.css';
import './assets/fonts/feather/feather.css';
import '././assets/libs/bootstrap-icons/font/bootstrap-icons.css';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import './assets/libs/dropzone/dist/dropzone.css';
import './assets/libs/bootstrap-select/dist/css/bootstrap-select.min.css';
import './assets/libs/datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import {
  Home,
  SignIn,
  Sale,
  Sales
} from './container';
import './config';

import PrivateRoute from './middleware/private';
import AuthRoute from './middleware/auth';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Home/>} />
        <Route path="/sign-in" element={<AuthRoute><SignIn/></AuthRoute>} />
        <Route path="/sales" element={<PrivateRoute><Sales/></PrivateRoute>}/>
        <Route path="/sale/:order_id" element={<PrivateRoute><Sale/></PrivateRoute>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
//@nftmarket.africa 2022
