import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import postApi from '../../service/api/post_api';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
Notify.init({position:'left-bottom'})
let api = new postApi();

export const createPost = createAsyncThunk(
  'post/CREATE_POST',
  async(nftDetails, thunkAPI)=>{
    let token = Cookies.get('c-token')
    if(token){
      let request = {
        nftDetails,
        token
      }
      try {
        let response = await api.createPost(request)
        if(response){
          let {post_id} = response.data
          // window.location.href=`/p/edit/${post_id}`
          return post_id
        }
      }catch(err){
        if(err.response && err.response.data){
          Notify.failure(err.response.data.error);
          throw thunkAPI.rejectWithValue(err.response.data.error);
        }else{
          Notify.failure('Network error');
          err.error = "Network Error";
          throw thunkAPI.rejectWithValue(err);
        }
      }
    }else{
      Notify.failure('Authentication required');
      throw thunkAPI.rejectWithValue('Token is missing');
    }
  }
)


export const getFeaturedPosts = createAsyncThunk(
  'post/GET_FEATURED_POSTS',
  async(thunkAPI)=>{
    try{
      let response = await api.getFeaturedPosts()
      return response.data;
    }catch(err){
      if(!err.response || !err.response.data){
        Notify.failure('Network error');
        throw thunkAPI.rejectWithValue('Network error');
      }else{
        Notify.failure(err.response.data.error);
        throw thunkAPI.rejectWithValue(err.response.data.error);
      }
    }
  }
)


export const getPost = createAsyncThunk(
  'post/GET_POST',
  async(post_id, thunkAPI)=>{
    try{
      let response = await api.getPost(post_id)
      return response.data;
    }catch(err){
      if(!err.response || !err.response.data){
        Notify.failure('Network error');
        throw thunkAPI.rejectWithValue('Network error');
      }else{
        Notify.failure(err.response.data.error);
        throw thunkAPI.rejectWithValue(err.response.data.error);
      }
    }
  }
)


export const updatePost = createAsyncThunk(
  'post/UPDATE_POST',
  async(update, thunkAPI)=>{
    let token = Cookies.get('c-token')
    if(token){
      let data = {
        update,
        token
      }
      try{
        let response = await api.updatePost(data)
        return response.data.message;
      }catch(err){
        if(!err.response || !err.response.data){
          Notify.failure('Network error');
          throw thunkAPI.rejectWithValue('Network error');
        }else{
          Notify.failure(err.response.data.error);
          throw thunkAPI.rejectWithValue(err.response.data.error);
        }
      }
    }else{
      throw thunkAPI.rejectWithValue("Token is required");
    }
  }
)


export const likePost = createAsyncThunk(
  'post/LIKE_POST',
  async(action, thunkAPI)=>{
    let token = Cookies.get('c-token')
    if(token){
      let {type, post_id} = action
      let data = {
        type,
        post_id,
        token
      }
      try{
        let response = await api.likePost(data)
        return response.data.message;
      }catch(err){
        if(!err.response || !err.response.data){
          Notify.failure('Network error');
          throw thunkAPI.rejectWithValue('Network error');
        }else{
          Notify.failure(err.response.data.error);
          throw thunkAPI.rejectWithValue(err.response.data.error);
        }
      }
    }else{
      throw thunkAPI.rejectWithValue("Token is required");
    }
  }
)

export const postSlice = createSlice({
  name:'post',
  initialState:{
    value:{
      featured:[],
      retrieved_post:null,
      post_id:'',
      creating:false,
      updating:false,
      updated:false,
      retrieving:false,
      fetching:false,
      message:"",
      error:null,
      account:Cookies.get('account')
    }
  },
  reducers:{
  },
  extraReducers:(builder)=> {
    builder.addCase(getFeaturedPosts.pending, (state, action)=> {
      state.value = {
        ...state.value,
        fetching:true,
        error:null
      }
    })
    builder.addCase(getFeaturedPosts.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        featured:action.payload,
        fetching:false,
        error:null
      }
    })
    builder.addCase(getFeaturedPosts.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        fetching:false,
        error:action.payload
      }
    })
    builder.addCase(createPost.pending, (state, action)=> {
      state.value = {
        ...state.value,
        creating:true,
        error:null
      }
    })
    builder.addCase(createPost.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        creating:false,
        error:false
      }
    })
    builder.addCase(createPost.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        creating:false,
        error:action.payload.error
      }
    })
    builder.addCase(getPost.pending, (state, action)=> {
      state.value = {
        ...state.value,
        retrieving:true,
        error:null
      }
    })
    builder.addCase(getPost.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        retrieving:false,
        retrieved_post:action.payload
      }
    })
    builder.addCase(getPost.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        retrieving:false,
        error:action.payload.error
      }
    })
    builder.addCase(updatePost.pending, (state, action)=> {
      state.value = {
        ...state.value,
        updating:true,
        updated:false,
        error:null
      }
    })
    builder.addCase(updatePost.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        updating:false,
        updated:true,
        message:action.payload,
        error:null
      }
    })
    builder.addCase(updatePost.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        updating:false,
        updated:false,
        error:action.payload.error
      }
    })
  }
})
export const {enter_action_here} = postSlice.actions;
export default postSlice.reducer;

//@NFTmarket.africa
