import {applyMiddleware} from 'redux';
import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../features/auth/authSlice';
import postReducer from '../features/post/postSlice';
import userReducer from '../features/user/userSlice';
import checkoutReducer from '../features/checkout/checkoutSlice';
import utilitySlice from '../features/utilities/utilitySlice';
import gallerySlice from '../features/gallery/gallerySlice';
import walletSlice from '../features/wallet/walletSlice';

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['checkout', 'post', 'user', 'gallery', 'wallet']
};

let reducers = combineReducers({
  auth:authReducer,
  post:postReducer,
  user:userReducer,
  checkout:checkoutReducer,
  utilities:utilitySlice,
  gallery:gallerySlice,
  wallet:walletSlice
})

const persistedReducer = persistReducer(persistConfig, reducers)

let store = configureStore({reducer:persistedReducer, middleware:getDefaultMiddleware({serializableCheck:false})}, applyMiddleware  )

const persistor = persistStore(store);

export {persistor}
export default store
