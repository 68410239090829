import React, {useEffect, useState} from 'react';
import PostCardHeader from './card_header';
import PostCardContent from './card_content';

  const Post =(props)=> {
    let [data, setData] = useState({});

    useEffect(()=>{
      if(props.data&&props.data.alchemy_data){
        let {alchemy_data} = props.data;
        let {metadata} = alchemy_data;
        metadata = JSON.parse(metadata)
        setData(metadata)
      }
    },[props.data])
  return (
      <div id={props.data.id} className="card rounded-3 shadow-md">
          <PostCardHeader {...props} metadata={data}/>
          <PostCardContent {...props}/>
          <div className="py-5"/>
      </div>
  )
  }
  export default Post;
//@ NFTmarket.africa 2022
