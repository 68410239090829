import React, {useEffect, useState} from 'react';
import checkmark from '../../assets/images/tools/checkmark.svg';
import avatar from '../../assets/images/avatar/user.png';
import {host} from '../../config';

export default function PostCardHeader(props){
  let [profile, setProfile] = useState({...props.profile})
  useEffect(()=>{
    setProfile(props.profile);
  },[props.profile])

  let copyLink =(e)=> {
    e.preventDefault()
    const body = document.querySelector('body');
    const area = document.createElement('textarea');
    body.appendChild(area);
    area.value = `${window.location.origin}/p/${props.data.id}`;
    area.select();
    document.execCommand('copy');
    body.removeChild(area);
  }

  return (
    <div className="position-relative card-header bg-white py-3 border-0 d-flex justify-content-between align-items-center" style={{zIndex:1}}>
      <div className="">
        <a href={`${host}/@/${profile.username}`}>
          <img
            src={profile.avatar|| avatar}
            alt="avatar"
            className="rounded-circle avatar-md"
          />

          {
            profile.profile_verified?
            <span
              className="position-absolute mt-2 ms-n3"
              data-bs-toggle="tooltip"
              data-placement="top"
              title="Verifed"
            >
              <img
                src={checkmark}
                alt="checkmark"
                height={25}
                width={25}
              />
            </span>
            :
            null
          }

        <span className="inline position-relative px-3 h6 fw-bold">
          @{profile.username}
        </span>
        </a>
      </div>

        <div>
          <span className="dropdown">
            <a
              className="text-decoration-none btn-icon btn-white rounded-circle text-muted"
              href="/"
              role="button"
              id="shareDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fe fe-share-2 fs-4 " />
            </a>
            <span className="dropdown-menu" aria-labelledby="shareDropdown">
              <span className="dropdown-header">Share</span>
              <a className="dropdown-item"
                href={`https://twitter.com/intent/tweet?url=${window.location.origin}/p/${props.data.id}&via=nftmarketafrica&text=Check out this NFT "${props.metadata?props.metadata.name:""}"`}
                target="blank_"
                >
                <i className="fas fa-info dropdown-item-icon" />
                Order Info
              </a>
              <a className="dropdown-item" href="/" onClick={copyLink}>
                <i className="fas fa-copy dropdown-item-icon" />
                Cancel Order
              </a>
            </span>
          </span>
        </div>

    </div>
  )
}
//@NFTmarket.africa 2022
