import axios from 'axios';
import {baseURL} from '../../config';

let postClient = axios.create({
  baseURL,
  timeout:20000
})

class PostApi {

  async getPost(post_id){
    return await postClient.get(`/posts/id/${post_id}`)
  }

  async createPost(data){
    return await postClient.post('/posts/create', data.nftDetails, {
      headers:{"x-token":data.token}
    })
  }

  async updatePost(data){
    return await postClient.post(`/posts/update/${data.update.post_id}`, data.update, {
      headers:{"x-token":data.token}
    })
  }

  async getFeaturedPosts(){
    return await postClient.get('/posts/featured')
  }

  async likePost(data){
    return await postClient.post(`/posts/like/${data.post_id}`, {type:data.type}, {
      headers:{"x-token":data.token}
    })
  }

  async getLikes(data){
    return await postClient.get(`/posts/like/${data.post_id}`, {
      headers:{"x-token":data.token}
    })
  }
}
export default PostApi;
//@NFTmarket.africa 2022
