import axios from 'axios';
import {baseURL} from '../../config';

let walletClient = axios.create({
  baseURL,
  timeout:20000
})

class WalletApi {
  async activateWallet(data){
    return await walletClient.post(`/wallets/activate/`, {}, {
      headers:{
        "x-token":data.token
      }
    });
  }

  async getWallets(data){
    return await walletClient.get(`/wallets`, {
      headers:{
        "x-token":data.token
      }
    });
  }
}

export default WalletApi;
//@NFTmarket.africa 2022
